<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Barcode Configuration</h1>
      <div class="btns-container">
        <VasionButton
          v-if="isDataAvailable"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="createNewClicked()"
        >
          New Barcode Config
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div v-if="whiteScreen === true">
      <!-- Show white space while waiting for data to load -->
      </div>
      <div v-else-if="isDataAvailable === true && tableData.Columns" class="main-table-container vasion-html-table-default">
        <VasionTable
          :headerColumns="tableData.Columns.Values"
          :tableRows="tableData.Rows.Values"
          :hideColumns="hiddenColumns"
          :filterByColumnDefault="1"
          :ellipsisButtonConfig="ellipsisButtonConfig"
          @ellipsis-button-clicked="ellipsisButtonClicked"
          @vasion-selection="rowClickHandler"
        />
      </div>
      <div v-else class="no-items-div">
        <div class="vasion-page-subheader instructional-message-div">
          No data to show.
        </div>
        <div class="button-div">
          <VasionButton :isFullWidth="true" :classProp="'primary'" @vasionButtonClicked="createNewClicked()">
            <span class="vasion-button-plus"> + </span> ADD NEW
          </VasionButton>
        </div>
      </div>
    </div>
    <md-dialog id="vasion-confirmation-div" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deleteMessage" @noButtonClick="showDeleteDialog = !showDeleteDialog" @yesButtonClick="deleteConfig()" />
    </md-dialog>
    <VasionSnackbar
      id="barcode-config-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'BarcodeConfiguration',
  components: {
    Loading,
  },
  data: function () {
    return {
      barcodeList: [],
      columns: {
        Values: [
          '_AipID_',
          'Name',
          'Object',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      deleteConfigId: '',
      deleteConfigName: '',
      deleteMessage: '',
      hiddenColumns: ['_AipID_'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      showDeleteDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: {},
      whiteScreen: true,
    }
  },
  computed: {
    ellipsisButtonConfig() {
      return {
        buttons: [
          {
            id: 'edit-barcode-configuration-button',
            name: 'Edit',
            icon: 'VasionEditIcon',
          },
          {
            id: 'delete-barcode-configuration-button',
            name: 'Delete',
            icon: 'VasionDeleteIcon',
          },
        ],
      }
    },
    isDataAvailable() { return this.barcodeList && this.barcodeList.length > 0 },
  },
  async created() {
    this.isLoading = true
    this.$store.dispatch('capture/setBarcodeData', {})
    this.barcodeList = await this.$store.dispatch('capture/getAllBarcodeIndex')
    this.whiteScreen = false

    const data = {
      list: this.barcodeList,
      columns: this.columns,
      type: 'BarcodeConfig',
    }
    this.tableData = await this.$store.dispatch('capture/setTableData', data)
    this.isLoading = false
    this.updateTableData()
  },
  methods: {
    createNewClicked() { this.$router.push({ name: 'EditBarcode', params: { barcodeId: 0 } }) },
    async deleteConfig() {
      this.isLoading = true
      const response = await this.$store.dispatch('common/deleteBarcodeConfig', this.deleteConfigId)
      this.updateTableData()
      this.showDeleteDialog = false

      this.snackbarTitle = response ? `"${this.deleteConfigName}" Deleted Successfully` : 'Error'
      this.snackbarSubTitle = response ? 'The Barcode Configuration has been removed' : 'Config not deleted'
      this.snackbarImage = response
      this.showSnackbarBool = true;
    },
    async editBarcode(barcodeId) {
      this.isLoading = true
      const barcodeData = this.barcodeList.find(barcode => barcode.ID === barcodeId)
      await this.$store.dispatch('capture/setBarcodeData', barcodeData)
      this.isLoading = false
      this.$router.push({ name: 'EditBarcode' })
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const [id, name] = payload.item.Values
        switch (payload.buttonId) {
          case 'edit-barcode-configuration-button':
            this.editBarcode(id)
            break;
          case 'delete-barcode-configuration-button':
            this.deleteMessage = `Are you sure you want to delete "${name}"?`
            this.deleteConfigId = id
            this.deleteConfigName = name
            this.showDeleteDialog = true
            break;
          default:
            break;
        }
      }
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.editBarcode(data.Values[0])
    },
    async updateTableData() {
      this.isLoading = true
      this.barcodeList = await this.$store.dispatch('capture/getAllBarcodeIndex')
      this.whiteScreen = false

      const data = {
        list: this.barcodeList,
        columns: this.columns,
        type: 'BarcodeConfig',
      }
      this.tableData = await this.$store.dispatch('capture/setTableData', data)
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.button-div {
  margin: auto;
  margin-top: 30px;
  width: 288px;
}
  
.instructional-message-div {
  margin-top: 24px;
}

.no-items-div {
  margin-top: 60px;
}

.grid-div {
  height: calc(100vh - 224px);
  overflow: auto;
  text-align: left;
}
</style>
